.newUIwelcomeSectionContainer {
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6);
  padding: 1rem;
}

.newUIwelcomeSectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.newUIwelcomeSectionLogo img {
  width: 5em;
}

.newUIwelcomeSectionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 4rem;
}

.newUIwelcomeSectionContent h3 {
  color: white;
  font-size: calc(1.5rem + 1.5vw);
  margin-bottom: 2rem;
  /* width: 30.6rem; */
}

.newUIwelcomeSectionContent p {
  color: white;
  font-size: calc(0.6rem + 0.6vw);
  margin-bottom: 6rem;
  width: 70vw;
}

.homeBgImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.img-wrapper {
  position: relative;
  width: 100%;
  height: 75vh;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 400px) {
  .newUIwelcomeSectionLogo img {
    width: 3em;
  }
  .newUIwelcomeSectionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 2rem;
  }
}

@media (max-width: 350px) {
  .newUIwelcomeSectionLogo img {
    width: 2em;
  }
}