.mapContainer {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.mapContainer h3 {
  font-size: calc(1.5rem + 1.5vw);
  color: #2ba988;
  padding-bottom: 2rem;
}

.mapContainer span {
  background-color: gold;
  color: #053348;
}

.road {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.step {
  flex: 1;
  text-align: center;
  margin: 10px;
  position: relative;
  min-width: 100px;
}

.step-header {
  background-color: #eee;
  color: #053348;
  padding: 10px;
  cursor: pointer;
}

.sub-steps {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  padding: 10px;
  z-index: 1;
  display: none;
}

.step:hover .sub-steps {
  display: block;
}

.sub-steps a {
  display: block;
  color: #053348;
  text-decoration: none;
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
}

.sub-steps a:hover {
  background-color: #ddd;
}

.gbaMapImg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}

.gbaMapImg img {
  width: 50%;
  height: 50%;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {
  .road {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .step {
    margin: 5px;
  }

  .step-header {
    font-size: 14px;
    padding: 5px;
  }

  .sub-steps {
    top: auto;
    left: 0;
    right: 0;
    transform: none;
    padding: 5px;
  }
}

@media (max-width: 1000px) {
  .gbaMapImg img {
    width: 100%;
    height: 100%;
  }
}
