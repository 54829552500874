* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  position: relative;
  min-height: 100%;
  padding-bottom: 20rem; /* Height of the footer */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  width: 70vw;
  height: 100%;
  border-color: #e5f2f2;
  margin: auto;
}

/* scrollbar styling */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  display: "none";
}

::-webkit-scrollbar-track {
  background: #e5f2f2;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #2ba988;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #185c76;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
