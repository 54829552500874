.footer {
  background-color: #053348;
  color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20rem;
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo img {
  width: 150px;
  padding-bottom: 1rem;
}

.footer-contact ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-contact li {
  padding: 0.2rem;
}

.footer-contact li i {
  margin-right: 0.2rem;
  font-size: calc(0.6rem + 0.6vw);
}

.footer-copy p {
  margin: 0;
  font-size: calc(0.4rem + 0.4vw);
  padding: 1rem;
}

@media (max-width: 768px) {
  .footer-logo img {
    width: 20%;
  }
}
