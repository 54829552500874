.aboutUsTitle {
  font-size: calc(1.5rem + 1.5vw);
  color: #2ba988;
}

.aboutUsTitlesSpan {
  background-color: #185c76;
}
.aboutUsContainer {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image img {
  width: 100%;
  height: 50%;
  /* border-radius: 20%; */
  padding-top: 2rem;
}

.paragraph {
  flex-basis: 70%;
}

.paragraph p {
  padding: 1rem;
  font-size: calc(0.6rem + 0.6vw);
  color: #185c76;
}

.paragraph p span {
  font-size: calc(0.6rem + 0.6vw);
  color: #2ba988;
  font-weight: bold;
}

.sloganAboutUs {
  font-size: calc(0.8rem + 0.8vw);
  color: #185c76;
  padding: 1rem;
  font-weight: bold;
}

@media (max-width: 780px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
